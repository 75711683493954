<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
               
              @click="$router.push({name: 'admin.institution-types.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="types"
              :sort-mapping="sortMapping"
              :title="'hosts types'"
              :total="typesTotal"
              :fetch-path="'institutions/fetchTypes'"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    @click="$router.push({name: 'admin.institution-types.edit', params: { id: field.id }})"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete area & group"
                    @click="deleteItem(field.id)"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'Order',
          checked: true,
          order: 1,
        },
        {
          name: 'Name',
          checked: true,
          order: 2,
        },
      ],
      sortMapping: {
        Name: 'name',
        Order: 'order',
      },
      title: 'Hosts types',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      types: 'institutions/types',
      typesTotal: 'institutions/typesTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('institutions/fetchTypes')
    await this.$store.dispatch('modals/fetchUserFields', 'institution-types')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'institution-types',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async deleteItem(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'institutions/fetchTypes', url: `${Vue.prototype.$groupUrl}/institutions/types/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
